.list-selector {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.list-input {
  width: 80%;
  padding: 10px 10px 10px 60px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  font-family: Mulish;
  font-size: var(--M);
  font-weight: var(--Regular);
}

.list-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.list-dropdown {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.list-option {
  padding: 10px;
  cursor: pointer;
  transition: background 0.2s;
}

.list-option:hover {
  background: #f1f1f1;
}

.list-selected {
  background: #007bff;
  color: white;
}
